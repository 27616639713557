import GlobalRoutes from "./navigations/GlobalRoutes";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { QueryClientProvider, QueryClient } from "react-query";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    // register service worker
    if (navigator.serviceWorker) {
      navigator.serviceWorker
        .register("./service-worker.js")
        .then(() => console.log("Service worker registered"))
        .catch((err) => console.log("Service worker not registered", err));
    }
  }, []);

  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <GlobalRoutes />
        <Toaster />
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
